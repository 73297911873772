<template>
    <div class="desc">
        <div class="wrap_box">
            <div class="bt">
                <p class="title">维保品件集采，拓展运输公司车后增值业务</p>
                <p class="desc">既能增加车主粘性，又能带来经济效益</p>
            </div>
            <ul class="desc_b">
                <li>
                    <div class="pic wow fadeInLeftBig">
                        <img src="@/assets/images/desc_pic1.png" alt="">
                    </div>
                    <div class="content wow fadeInRightBig">
                        <div class="content_t">
                            <img src="@/assets/images/desc_title1.png" alt="">
                        </div>
                        <div class="content_b">
                            <p>从“车辆挂靠”升级到“智能用车服务”，车主科学用车，实时监测车辆的使用情况并可以及时维护；</p>
                            <p>增强运输公司同业竞争力，强化客户粘性</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="content wow fadeInLeftBig" data-wow-delay="0.3s">
                        <div class="content_t">
                            <img src="@/assets/images/desc_title2.png" alt="">
                        </div>
                        <div class="content_b">
                            <p>连接车与人，连接维保服务生态；辅助运输公司向服务领域拓展，开辟新的盈利渠道</p>
                        </div>
                    </div>
                    <div class="pic wow fadeInRightBig" data-wow-delay="0.3s">
                        <img src="@/assets/images/desc_pic2.png" alt="">
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { WOW } from 'wowjs'
export default {
  mounted () {
    var wow = new WOW({
      boxClass: 'wow', // animated element css class (default is wow)
      animateClass: 'animated', // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: false, // act on asynchronously loaded content (default is true)
      callback: function (box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null, // optional scroll container selector, otherwise use window,
      resetAnimation: true // reset animation on end (default is true)
    })
    wow.init()
  }
}
</script>
<style lang="scss" scoped>
.desc{
    width: 100%;
    background: url('~@/assets/images/desc_bg.png');
    overflow: hidden;
    .bt{
        margin-top: 108px;
    }
    .desc_b{
        margin-top: 100px;
        padding-bottom: 93px;
        li{
            display: flex;
            .pic{
                width: 600px;
                img{
                    display: block;
                    margin:0 auto;
                }
            }
            .content{
                width: 600px;
            }
            &:nth-of-type(1){
                .content{
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    height: 413px;
                    .content_t{
                        width: 465px;
                        margin:0 auto;
                    }
                    .content_b{
                        width: 465px;
                        margin: 30px auto 0;
                        p{
                            font-size: 22px;
                            font-weight: 400;
                            color: #333333;
                            &:nth-of-type(1){
                                line-height: 50px;
                            }
                            &:nth-of-type(2){
                                margin-top: 40px;
                            }
                        }
                    }
                }
            }
            &:nth-of-type(2){
                margin-top: 100px;
                .content{
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    height: 417px;
                    .content_t{
                        width: 340px;
                        margin:0 auto;
                    }
                    .content_b{
                        width: 340px;
                        margin: 30px auto 0;
                        p{
                            font-size: 22px;
                            font-weight: 400;
                            color: #333333;
                            line-height: 50px;
                        }
                    }
                }
            }
        }
    }
}
</style>
