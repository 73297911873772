<template>
    <div class="safe">
        <div class="wrap_box">
            <div class="bt">
                <p class="title">安全检查上报 — 满足交管部门安全监管需求</p>
                <p class="desc">真实数据，低成本高效率</p>
            </div>
            <div class="content">
                <img src="@/assets/images/safe_info.png" alt="">
                <i class="icon safe_icon1 test aniamted my_bounce"></i>
                <i class="icon safe_icon2 test aniamted my_bounce"></i>
                <i class="icon safe_icon3 test aniamted my_bounce"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.safe{
    width: 100%;
    background: url('~@/assets/images/safe_bg.png') no-repeat center bottom;
    padding-bottom: 180px;
    .bt{
        margin-top: 122px;
    }
    .content{
        position: relative;
        img{
            display: block;
            width: 1075px;
            height: 657px;
            margin: 98px auto 0;
        }
        .icon{
            position: absolute;
            width: 70px;
            height: 101px;
        }
        .safe_icon1{
            top:330px;
            left:360px;
            background: url('~@/assets/images/safe_icon1.png') no-repeat center;
        }
        .safe_icon2{
            top:330px;
            left:724px;
            background: url('~@/assets/images/safe_icon1.png') no-repeat center;
        }
        .safe_icon3{
            top:330px;
            left:935px;
            background: url('~@/assets/images/safe_icon2.png') no-repeat center;
        }
    }
    .test{
        animation-duration: 5s;
        animation-delay: 0;
        animation-iteration-count:infinite;
        animation-direction:alternate;
    }
    @-webkit-keyframes mybounce {
        0%, 20%, 40%, 60%,80%, 100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        10%, 30%, 50%, 90% {
            -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
        }
    }
    @keyframes mybounce {
        0%, 20%, 40%, 60%,80%, 100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        10%, 30%, 50%, 90% {
            -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
        }
    }
    .my_bounce {
        -webkit-animation-name: mybounce;
        animation-name: mybounce;
    }

}
</style>
