<template>
    <div class="banner">
        <img class="bg" src="@/assets/images/banner.png" alt="">
        <div class="wrap_box">
            <div class="banner_l">
                <div class="banner_l_t">
                    <p class="title">小卡管车</p>
                    <div class="desc">
                        <p>快速拥有适合自己的管车专家，</p>
                        <p>降低管车成本，</p>
                        <p>共享商用车后市场收益</p>
                    </div>
                </div>
                <div class="banner_l_m">
                    <ul>
                        <li>
                            <i class="icon"></i>
                            <p class="title">无需开发</p>
                        </li>
                        <li>
                            <i class="icon"></i>
                            <p class="title">流量风口</p>
                        </li>
                        <li>
                            <i class="icon"></i>
                            <p class="title">极佳体验</p>
                        </li>
                        <li>
                            <i class="icon"></i>
                            <p class="title">超强粘性</p>
                        </li>
                    </ul>
                </div>
                <div class="banner_l_b">
                    <ul>
                        <li class="btn">
                            <a href="#submit">免费试用</a>
                        </li>
                        <li class="btn" @click="show">立即咨询</li>
                    </ul>
                </div>
            </div>
            <div class="banner_r">
                <swiper :autoplay="swiperOptions.autoplay" :loop="swiperOptions.loop" :speed="swiperOptions.speed" :pagination="swiperOptions.pagination">
                    <swiper-slide>
                        <div class="pic">
                            <img src="@/assets/images/banner1.png" alt="">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="pic">
                            <img src="@/assets/images/banner2.png" alt="">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="pic">
                            <img src="@/assets/images/banner3.png" alt="">
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
export default {
  name: 'Banner',
  components: {
    Swiper,
    SwiperSlide
  },
  setup () {
    const swiperOptions = reactive({
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      loop: true,
      speed: 500,
      pagination: {
        clickable: true
      }
    })
    // 将swiperOptions返回给模板中的swiper组件使用
    return { swiperOptions }
  },
  methods: {
    show () {
      this.$parent.show = true
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
    min-width: 1200px;
    position: relative;
    .bg{
        width: 100%;
        min-width: 1200px;
        height: 858px;
    }
    .wrap_box{
        display: flex;
        position: absolute;
        top: 0;
        left: 50%;
        height: 86%;
        transform: translate(-50%,0);
        justify-content: space-between;
    }
    .banner_l{
        width: 507px;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .banner_l_t{
            .title{
                font-size: 56px;
                font-weight: bold;
                color: #fff;
                letter-spacing:87px;
                white-space: nowrap;
            }
            .desc{
                font-size: 34px;
                color: #fff;
                line-height: 64px;
                padding-top: 50px;
                p{
                    white-space: nowrap;
                    letter-spacing: 3px;
                }
            }
        }
        .banner_l_m{
            margin: 50px 0;
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    .icon{
                        display: block;
                        width: 21px;
                        height: 21px;
                        background: url('~@/assets/images/right.png') no-repeat center;
                    }
                    .title{
                        font-size: 20px;
                        color: #FFFFFF;
                        padding-left: 14px;
                        letter-spacing: 3px;
                    }
                    &:nth-of-type(2)~li{
                        margin-top: 42px;
                    }
                }
            }
        }
        .banner_l_b{
            position: relative;
            height: 54px;
            background: #5D96F5;
            box-shadow: 0px 14px 32px 0px rgba(40, 83, 242, 0.18), 0px 1px 0px 0px rgba(40, 83, 200, 0.09);
            border-radius: 27px;
            ul{
                li{
                    cursor: pointer;
                    position: absolute;
                    width: 267px;
                    height: 54px;
                    text-align: center;
                    line-height: 54px;
                    font-size: 14px;
                    color: #FFFFFF;
                    &:nth-of-type(1){
                        top: 0;
                        left: 0;
                        background: #E2ECF1;
                        box-shadow: 0px 1px 0px 0px rgba(40, 83, 200, 0.09);
                        border-radius: 27px;
                        color: #497EFE;
                        a{
                            color: #497EFE;
                        }
                    }
                    &:nth-of-type(2){
                        top: 0;
                        right: 0;
                    }
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                    }
                }
            }
        }
    }
    .banner_r{
        display: flex;
        width: 643px;
        height: 100%;
        .pic{
            margin-top: 110px;
            width: 100%;
            height: 463px;
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
