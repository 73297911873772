<template>
    <div class="price">
        <div class="wrap_box">
            <div class="bt">
                <p class="title">小卡管车 — 服务体系与价格</p>
                <p class="desc">收最少的钱，做最好的事</p>
            </div>
            <div class="content">
                <img src="@/assets/images/price.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.price{
    overflow: hidden;
    padding-bottom: 82px;
    .bt{
        margin-top: 65px;
    }
    .content{
        margin-top: 80px;
        img{
            display: block;
            width: 1019px;
            height: 705px;
            margin:0 auto;
        }
    }
}
</style>
