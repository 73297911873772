<template>
  <div class="container">
    <banner/>
    <intro/>
    <description/>
    <safe/>
    <price/>
    <submit/>
    <Dialog v-model:visible="show" :w="300">
      <div class="box">
        <div class="box_t">
          <i class="icon"></i>
          <p>电话咨询热线：</p>
        </div>
        <p class="tel">020-38271966</p>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Banner from './components/banner.vue'
import Intro from './components/intro.vue'
import Description from './components/desc.vue'
import Safe from './components/safe.vue'
import Price from './components/price.vue'
import Submit from './components/submit.vue'
import Dialog from '@/components/dialog.vue'
export default {
  name: 'Index',
  components: {
    Banner,
    Intro,
    Description,
    Safe,
    Price,
    Submit,
    Dialog
  },
  data () {
    return {
      show: false
    }
  }
}
</script>
<style lang="scss" scoped>
.container{
  min-width: 1200px;
  margin: 0 auto;
}
.box{
  text-align: center;
  .box_t{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
      width: 23px;
      height: 23px;
      background: url("~@/assets/images/tel.png") no-repeat center;
    }
    p{
      font-size: 16px;
      color: #666666;
      margin-left: 14px;
    }
  }
  .tel{
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    background: linear-gradient(0deg, #2362FB 0%, #5889FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 33px;
    border-bottom: 1px solid #2362FB;
  }
}
</style>
