<template>
    <div class="intro">
        <div class="wrap_box">
            <div class="bt">
                <p class="title">挂靠业务智慧化，管理更科学</p>
                <p class="desc">通过智慧管理，实现核心价值</p>
            </div>
            <div class="content">
                <ul>
                    <li>
                        <div class="item_l wow bounceInLeft" data-wow-delay="0.3s">
                            <div class="item_l_t">
                                <img src="@/assets/images/intro_title1.png" alt="">
                            </div>
                            <div class="item_l_b">
                                <ul>
                                    <li>挂靠业务流程管理全面</li>
                                    <li>应付已付账目清晰明了</li>
                                    <li>欠款在线缴纳省时省力</li>
                                    <li>在线申报出险方便快捷</li>
                                    <li>证照代办一键便利提交</li>
                                </ul>
                            </div>
                        </div>
                        <div class="item_r wow bounceInLeft" data-wow-delay="0">
                            <img src="@/assets/images/intro_pic1.png" alt="">
                        </div>
                    </li>
                    <li>
                        <div class="item_r wow bounceInRight" data-wow-delay="0.6s">
                            <img src="@/assets/images/intro_pic2.png" alt="">
                        </div>
                        <div class="item_l wow bounceInRight" data-wow-delay="0.9s">
                            <div class="item_l_t">
                                <img src="@/assets/images/intro_title2.png" alt="">
                            </div>
                            <div class="item_l_b">
                                <ul>
                                    <li>业务档案记录全面准确</li>
                                    <li>财务账目流水一目了然</li>
                                    <li>欠款催缴提醒功能强大</li>
                                    <li>司机缴款平账便捷易用</li>
                                    <li>合同规则定制适应性广</li>
                                    <li>电子合同安全高效便捷</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="row wow bounceInUp" data-wow-delay="0">
                            <div class="row_l">
                                <img src="@/assets/images/intro_icon1.png" alt="">
                            </div>
                            <div class="row_r">
                                <p class="row_r_t">运输企业管理人</p>
                                <p class="row_r_b">掌握企业挂靠业务状态及时发现问题，降低企业风险、降低作业成本、提升经营效率</p>
                            </div>
                        </div>
                        <div class="row wow bounceInUp" data-wow-delay="0.3s">
                            <div class="row_l">
                                <img src="@/assets/images/intro_icon2.png" alt="">
                            </div>
                            <div class="row_r">
                                <p class="row_r_t">运输企业操作员</p>
                                <p class="row_r_b">主营业务数据智能录入，基本运营业务数据查阅 提升作业效率、降低作业出错率、减轻工作量</p>
                            </div>
                        </div>
                        <div class="row wow bounceInUp" data-wow-delay="0.6s">
                            <div class="row_l">
                                <img src="@/assets/images/intro_icon3.png" alt="">
                            </div>
                            <div class="row_r">
                                <p class="row_r_t">挂靠卡车司机</p>
                                <p class="row_r_b">获得卡车挂靠业务各类信息提醒，在线缴纳费用 明悉账务情况，避免违约风险、提升个人信用度</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <img class="bg" src="@/assets/images/intro_bg.png" alt="">
                <p class="content_title1">智慧管理</p>
                <p class="content_title2">核心价值</p>
            </div>
            <div class="content_b wow flipInY">
                <img src="@/assets/images/intro_bottom.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.intro{
    .content{
        position: relative;
        margin-top: 55px;
        height: 1278px;
        overflow: hidden;
        .bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .content_title1{
            width: 252px;
            height: 74px;
            text-align: center;
            line-height: 74px;
            position: absolute;
            font-size: 30px;
            color: #FFFFFF;
            top: 0;
            left: 445px;
        }
        .content_title2{
            width: 252px;
            height: 74px;
            text-align: center;
            line-height: 74px;
            position: absolute;
            font-size: 30px;
            color: #FFFFFF;
            bottom: 0;
            left: 445px;
        }
        >ul{
            >li{
                display: flex;
                position: relative;
                z-index: 99;
                .item_l{
                    width: 600px;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    .item_l_t{
                        width: 400px;
                        margin: 0 auto;
                    }
                    .item_l_b{
                        width: 400px;
                        margin: 30px auto 0;
                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            li{
                                flex-shrink: 0;
                                width: 50%;
                                font-size: 18px;
                                color: #666666;
                            }
                            li:nth-of-type(2)~li{
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .item_r{
                    position: relative;
                    width: 600px;
                    height: 402px;
                    img{
                        position: absolute;
                        top:50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
                &:nth-of-type(1){
                    margin-top: 40px;
                }
                &:nth-last-child(1){
                    display: block;
                    margin-top: 60px;
                    .row{
                        display: flex;
                        width: 860px;
                        margin:0 auto;
                        .row_l{
                            flex-shrink: 0;
                            width: 54px;
                            height: 51px;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .row_r{
                            display: flex;
                            flex-flow: column;
                            justify-content: space-between;
                            margin-left: 60px;
                            .row_r_t{
                                font-size: 18px;
                                font-weight: 500;
                                color: #333333;
                            }
                            .row_r_b{
                                font-size: 16px;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                    .row+.row{
                        margin-top: 34px;
                    }
                }
            }
        }
    }
    .content_b{
        margin-top: 72px;
        padding-bottom: 94px;
        img{
            width: 1200px;
        }
    }
}
</style>
