<template>
    <div class="dialog_wrap" v-if="visible">
        <div class="dialog_container" :style="{width:w+'px'}">
            <div class="dialog_container_t">
                <i class="close" @click="$emit('update:visible',false)">×</i>
            </div>
            <div class="dialog_container_b">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    w: {
      type: Number,
      default: 300
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog_wrap{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 9999;
    .dialog_container{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: #fff;
        border-radius: 5px;
        .dialog_container_t{
            position: relative;
            width: 100%;
            height: 30px;
            .close{
                cursor: pointer;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translate(0,-50%);
                font-size: 14px;
                font-weight: bold;
                color: #666666;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
            }
        }
        .dialog_container_b{
            padding-bottom: 44px;
        }
    }
}
</style>
