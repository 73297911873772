<template>
    <div id="submit" class="submit">
        <div class="wrap_box">
            <p class="title">欢迎登记信息，我们会尽快联系您</p>
            <el-form class="form" ref="form" :model="form" label-width="150px" :rules="rules">
                <el-form-item label="您的姓名：" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="您的电话：" prop="phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="企业名称：" prop="corporName">
                    <el-input v-model="form.corporName"></el-input>
                </el-form-item>
            </el-form>
            <button class="btn" type="submit" @click="onSubmit">提交</button>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入您的电话'))
      } else {
        if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }
    }
    return {
      form: {
        name: '',
        phone: '',
        corporName: ''
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        corporName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.axios.post('/api/application/add', this.form).then(res => {
            this.form = {
              name: '',
              phone: '',
              corporName: ''
            }
            if (res.code === '0') {
              this.$message.success('提交成功')
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.submit{
    width: 100%;
    padding-bottom: 90px;
    background: url('~@/assets/images/submit_bg.png') no-repeat center top;
    overflow: hidden;
    .title{
        font-size: 30px;
        font-weight: 500;
        color: #FFFFFF;
        margin-top:155px;
        text-align: center;
    }
    .form{
        margin-top: 40px;
        .el-form-item{
            width: 620px;
            margin: 0 auto 22px;
            ::v-deep label{
                font-size: 20px;
                font-weight: 400;
                color: #FFFFFF;
            }
            ::v-deep .el-input{
                display: inline-block;
                width: 460px;
                height: 40px;
                border: none;
                border-radius: 30px;
                padding:0 15px;
                .el-input__wrapper{
                    width: 100%;
                }
            }
            ::v-deep .el-form-item__error{
                padding-left: 16px;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    .btn{
        display: block;
        width: 180px;
        height: 50px;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 2px 5px 1px rgba(0, 15, 38, 0.23);
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        margin:50px auto 0;
        font-size: 22px;
        font-weight: 500;
        color: #FFFFFF;
        background: transparent;
        cursor: pointer;
    }
}
</style>
